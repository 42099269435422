/* eslint-disable no-console */
import { Core } from '@nsftx/systems-sdk';
import { axiosErrorParser, errorParser } from '@/common/services/error-parser';
import { logService } from '@/common/services/logger';
import { initDmDisplay, switchDisplay, getApplication } from './deviceManagamentDisplayService';
import DMGetDataResponse from '../interfaces/DMGetDataResponse';
import DMGetApplicationData from '../interfaces/DMGetApplicationData';
import DMGetApplicationConfigResponse from '../interfaces/DMGetApplicationConfigResponse';
import { getAppConfigData } from '../apiService';
import DmConfigDataError from '../errors/DmConfigDataError';
import DmCoreError from '../errors/DmCoreError';
import { getAppRunUuidFromUrl } from '../helpers';
import DmDisplayError from '../errors/DmDisplayError';

// Vars
let core: Core;
let isConnected = false;

const run = (): Promise<Core> => new Promise((resolve, reject) => {
  core = new Core('7Terminal');

  core.connect().catch((error) => {
    reject(new DmCoreError(
      errorParser.parseMessage(error),
      'T_DEVICE_MANAGEMENT_CORE_CONNECTION_ERROR',
      {
        cause: error,
      },
    ));
  });

  core.on('connection', () => {
    isConnected = true;

    initDmDisplay(core)
      .then(() => resolve(core))
      .catch((e) => {
        reject(new DmDisplayError(
          errorParser.parseMessage(e),
          'T_DEVICE_MANAGEMENT_DISPLAY_INIT_ERROR',
          {
            cause: e,
          },
        ));
      });
  });
});

/**
 * @throws {DmCoreError}
 */
const getCore = (): Promise<Core> => new Promise((resolve, reject) => {
  if (isConnected) {
    resolve(core);
    return;
  }

  reject(new DmCoreError(
    'Core it not yet initialized',
    'T_DEVICE_MANAGEMENT_CORE_NOT_INITIALIZED',
  ));
});

const getApplicationConfigUrl = () => {
  const appRunUuid = getAppRunUuidFromUrl() || '';
  return getApplication(appRunUuid);
};

/**
 * @throws {DmConfigDataError}
 */
const getApplicationConfig = (): Promise<DMGetApplicationConfigResponse> => new Promise(
  (resolve, reject) => {
    getApplicationConfigUrl()
      .then((data: string) => {
        const parsedData: DMGetApplicationData = JSON.parse(data);
        if (!parsedData.config_url) {
          reject(new DmConfigDataError(
            'Config url is empty',
            'T_DEVICE_MANAGEMENT_DISPLAY_DATA_CONFIG_URL_EMPTY',
          ));
          return;
        }

        getAppConfigData(parsedData.config_url)
          .then((res) => {
            resolve(res.data);
          }).catch((err) => {
            const parsedAxiosErr = axiosErrorParser.parseUpstream(err);
            logService.error('[deviceManagementService] Failed to fetch application config data', {
              code: 'T_DEVICE_MANAGEMENT_CONFIG_DATA_FETCH_FAILED',
              ...parsedAxiosErr,
            });
            reject(new DmConfigDataError(
              'Failed to fetch application config data',
              'T_DEVICE_MANAGEMENT_CONFIG_DATA_FETCH_FAILED',
              {
                cause: err,
              },
            ));
          });
      })
      .catch(() => {
        reject(new DmConfigDataError(
          'Failed to fetch application config url',
          'T_DEVICE_MANAGEMENT_CONFIG_URL_FETCH_FAILED',
        ));
      });
  },
);

const getData = (): Promise<DMGetDataResponse> => new Promise((resolve, reject) => {
  getCore().then(() => {
    // if we don't receive data from DM in 5 seconds, reject the bootstrap request
    const getDataTimer = setTimeout(() => {
      const error = new Error('Failed to fetch data from DM runtime.');
      error.name = 'T_DEVICE_MANAGEMENT_DATA_FETCH_FAILED';
      reject(error);
    }, 5000);

    return Promise.all([
      getApplicationConfig(),
      core.deviceState(),
    ])
      .then((data) => {
        clearTimeout(getDataTimer);
        const [appConfigData, deviceStateData] = data;

        resolve({
          device: {
            data: {
              device: {
                name: deviceStateData.device.name,
                uuid: deviceStateData.device.uuid,
                deviceId: deviceStateData.device.device_id,
              },
              betshop: {
                betshopId: deviceStateData.location.betshop_id,
                companyId: deviceStateData.space.company_id,
                uuid: deviceStateData.location.uuid,
                name: deviceStateData.location.name,
                companyName: deviceStateData.space.name,
                companyUuid: deviceStateData.space.uuid,
              },
            },
          },
          appConfig: {
            data: {
              appConfig: {
                terminalAccount: {
                  uuid: appConfigData.app_config.terminal_account.uuid,
                  password: appConfigData.app_config.terminal_account.password,
                },
              },
              sevenUrl: appConfigData.seven_url,
              sevenSocketUrl: appConfigData.seven_socket_url,
            },
          },
        });
      })
      .catch(reject);
  });
});

const switchUpperScreen = (accountId: string) => switchDisplay(accountId);

export default {
  run,
  getCore,
  getData,
  getApplicationConfigUrl,
  getApplicationConfig,
  switchUpperScreen,
};
