import { useConfigSettingsStore } from '@/modules/seven/config-settings/store';
import { useLanguageStore } from '@/common/stores/language';
import taxAuthorityModule from '@/modules/tax-authority';
import { useClientGamesStore } from '@/modules/client-games';
import { userFundsService } from '@/modules/user-funds';
import { safeAppReload } from '@/common/services/appHelpersService';

(function () {
  angular.module('7Terminal.Core')
    .service('TerminalService', TerminalService);

  function TerminalService(
    $q,
    $log,
    $rootScope,
    currency,
    LocalData,
    nabTrans,
    BootStrapData,
    SevenScmSvc,
    SevenGamesSvc,
    ticketManager,
    printServiceApi,
    NabNotifications,
    Peripherals,
    nabMessaging,
    // eslint-disable-next-line no-unused-vars
    ticketService, // although not called anywhere if removed we won't init events inside this service
  ) {
    var gamesTax = { config: {} };
    const configSettingsStore = useConfigSettingsStore();
    const languageStore = useLanguageStore();

    return {

      init: function () {
        const gamesStore = useClientGamesStore();
        var activeGames;
        var failedConfigGames;
        var failedConfigGamesNames = [];
        var i = 0;
        var game;

        userFundsService.startBalanceCheckInterval();

        // get games with failed games config request
        failedConfigGames = SevenGamesSvc.failedGames;

        if (failedConfigGames.length) {
          // show notification message with names of failEd games

          angular.forEach(failedConfigGames, function (value) {
            this.push(nabTrans.translate('games.' + value.id.toLowerCase(), {}, true));
          }, failedConfigGamesNames);

          NabNotifications.show({
            message: nabTrans.translate('notifications.config_load_failed', {
              games: failedConfigGamesNames.join(', ')
            }, true),
            type: 'info',
            delay: 5000
          });
        }

        // currency constant
        currency.value = BootStrapData.company.currency;

        languageStore.setLanguages();
        // Set ticket config data
        ticketManager.setData();
        activeGames = SevenGamesSvc.getGames();
        for (i; i < activeGames.length; i++) {
          game = activeGames[i];
          printServiceApi.updateOddsConfig(game.id, game.config);
          // TODO fix this bullshit when lucky six adds taxes
          gamesTax.config[game.id] = game.config.taxes || { payin: { value: false }, payout: { value: false } };
          gamesStore.addGameConfig(game.id, game.config);
        }

        printServiceApi.updateTicketConfig({
          header: {
            betshopName: BootStrapData.betshop.name,
            betshopAddress: BootStrapData.betshop.address,
            operatorName: this.username || '',
            issueDateTitle: nabTrans.translate('print.issue_date', {}, true),
            validUntilDateTitle: nabTrans.translate('print.valid_until', {}, true),
            voucherIdTitle: nabTrans.translate('print.voucher_id', {}, true),
            ticketIdTitle: nabTrans.translate('print.ticket_id', {}, true)
          },
          tax: gamesTax.config
        });
        printServiceApi.updateCommonData({
          betshop: BootStrapData.betshop,
          company: BootStrapData.company,
          device: LocalData.device,
          terminal: {
            type: nabTrans.translate('main.anonymous_terminal', {}, true)
          }
        });

        Peripherals.init();
        taxAuthorityModule.init();

        return $q.resolve();
      },

      startEvents: function () {
        var balanceUpdateListner;
        var betshopRolesChangedListener;

        // listen for changes in balance from scm
        balanceUpdateListner = nabMessaging.subscribe('NCM:balanceUpdated', function () {
          userFundsService.updateBalance().catch((err) => {
            $log.error('[7Terminal.Core] Failed to fetch balance when requested by server.', {
              upstream_code: (err.data?.httpCode) || (err.data?.code) || (err.response?.status) || err.code || err.status,
              upstream_message: err.data?.message || err.response?.message || err.message || err.statusText,
              code: 'T_BALANCE_UPDATE_FETCH_FAILED'
            });
          });
        });

        // listen for changes in SCM conn object - show notification upon first connect success
        $rootScope.$watch(function () {
          return SevenScmSvc.conn;
        }, function (newData) {
          if (newData) {
            userFundsService.updateBalance().catch((err) => {
              $log.error('[7Terminal.Core] Failed to fetch balance when requested by server on first connect.', {
                upstream_code: (err.data?.httpCode) || (err.data?.code) || (err.response?.status) || err.code || err.status,
                upstream_message: err.data?.message || err.response?.message || err.message || err.statusText,
                code: 'T_BALANCE_UPDATE_FETCH_FAILED'
              });
            });
          }
        });

        nabMessaging.subscribe('app:configChanged', function (event, data) {
          configSettingsStore.$patch({
            data: data.appConfig
          });
          languageStore.setLanguages();
        });

        betshopRolesChangedListener = nabMessaging.subscribe('NCM:betshopRolesChanged', function () {
          var permissionNotification = NabNotifications.show(
            {
              message: nabTrans.translate('betshop.changed_permissions_message', {}, true),
              type: 'warning',
              actions: [
                {
                  callback: function () {
                    NabNotifications.removeNotification(permissionNotification);
                    $log.error('[7Terminal.Core] Reload on betshop role change.', {
                      code: 'T_APP_BETSHOP_ROLE_CH_RELOAD'
                    });
                    safeAppReload();
                  },
                  text: nabTrans.translate('actions.ok', {}, true)
                }
              ]
            }

          );
        });

        nabMessaging.subscribe('app:configFetchingFailed', function (e, message) {
          NabNotifications.show({
            message: nabTrans.translate('notifications.config_fetching_failed', {
              game: message
            }, true),
            type: 'info',
            delay: 5000
          });
        });

        nabMessaging.subscribe('app:configFetchingSuccess', function (e, message) {
          const gamesStore = useClientGamesStore();
          printServiceApi.updateOddsConfig(message.id, message.config);
          gamesTax.config[message.id] = message.config.taxes || { payin: { value: false }, payout: { value: false } };
          printServiceApi.updateTicketConfig({ tax: gamesTax.config });
          gamesStore.addGameConfig(message.id, message.config);
          NabNotifications.show({
            message: nabTrans.translate('notifications.config_fetching_success', {
              game: message.info.translation
            }, true),
            type: 'info',
            delay: 5000
          });
        });

        $rootScope.$on('7T:User.AuthorizationChanged', function () {
          nabMessaging.unsubscribe(balanceUpdateListner);
          nabMessaging.unsubscribe(betshopRolesChangedListener);
        });
      }
    };
  }
})();
