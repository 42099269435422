import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import '@nsftx/seven-components/style'; // main style
import '@nsftx/seven-components/palettes/default';
import '@nsftx/seven-components/palettes/yellow-balkan-bet';
import '@nsftx/seven-components/palettes/yellow-pionir';
import '@nsftx/seven-components/styles/components/terminal';
import { SCModalsContainer } from '@nsftx/seven-components';
import { storeToRefs } from 'pinia';
import { computed, defineAsyncComponent } from 'vue';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useMarketingScreenSaverStore } from '@/modules/cms/marketing/marketingScreenSaverStore';
import { lobbyService } from '@/modules/lobby';
import TNotifications from '@/common/components/TNotifications.vue';
import WidgetsRenderer from '@/common/components/WidgetsRenderer.vue';
import { MaintenanceModal } from '@/modules/maintenance';
import SevenTicketPin from '@/modules/seven-ticket-security/SevenTicketPin.vue';
import router from './router';
import { isTboActive } from './common/services/helpers';
import { useEnvironmentHandlerStore } from './common/stores/environment-handler';
import { lpsSocketManager } from './modules/lps-socket';
import { connectionStatusService } from './modules/connection-status';
import { useConfigSettingsStore } from './modules/seven/config-settings';

// Screen saver

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const marketingScreenSaverStore = useMarketingScreenSaverStore();
const { isMarketingScreenSaverVisible } = storeToRefs(marketingScreenSaverStore);
const { isScreenSaverEnabled, showScreenSaver } = marketingScreenSaverStore;
const MarketingScreenSaver = defineAsyncComponent(() => import(
  /* webpackChunkName: "marketing_screen_saver_component" */
  '@/modules/cms/marketing/components/MarketingScreenSaver.vue'
));

// Lobby
const LobbyHome = defineAsyncComponent(() => import(
  /* webpackChunkName: "lobby_home_component" */
  '@/modules/lobby/components/LobbyHome.vue'
));

const IdleModalNotification = defineAsyncComponent(() => import(
  /* webpackChunkName: "idle_modal_notification" */
  '@/modules/inactivity-timer/components/IdleModalNotification.vue'
));

const showLobbyModal = computed(() => lobbyService.getIsModalShown());
const configSettingsStore = useConfigSettingsStore();
const envStore = useEnvironmentHandlerStore();
const gravitySettingsStore = useGravitySettingsStore();
const palette = gravitySettingsStore.getModuleDataKeyValue('config', 'palette') || 'default';

const routerClasses = computed(() => {
  if (isTboActive()) {
    return 'top-0';
  }
  if (router.currentRoute.value.name === 'TicketCheck') {
    return 'top-128';
  }
  return 'top-64';
});

if (isScreenSaverEnabled()) {
  showScreenSaver();
}

if (!envStore.isDmApplicationRuntime()) {
  lpsSocketManager.init();
  connectionStatusService.init();
}

document.documentElement.dataset.scChannel = 'terminal';
document.documentElement.dataset.scTheme = 'dark';
document.documentElement.dataset.scPalette = palette;

const handleCursorBehavior = () => {
  const shouldShowCursor = configSettingsStore.data.terminalCursor.value;

  if (!shouldShowCursor) {
    const globalStyleId = 'dynamic-global-style';
    const style = document.createElement('style');
    style.id = globalStyleId;
    style.innerHTML = '* { cursor: none !important; }';
    document.head.appendChild(style);
  }
};

handleCursorBehavior();


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: "vue-router",
      class: _normalizeClass(routerClasses.value)
    }, [
      _createVNode(_component_router_view)
    ], 2 /* CLASS */),
    _createVNode(_unref(SCModalsContainer)),
    (showLobbyModal.value)
      ? (_openBlock(), _createBlock(_unref(LobbyHome), { key: 0 }))
      : _createCommentVNode("v-if", true),
    _createVNode(TNotifications),
    (_unref(isMarketingScreenSaverVisible))
      ? (_openBlock(), _createBlock(_unref(MarketingScreenSaver), { key: 1 }))
      : _createCommentVNode("v-if", true),
    _createVNode(WidgetsRenderer, { position: "appModals" }),
    _createVNode(_unref(MaintenanceModal)),
    _createVNode(SevenTicketPin, { "data-testid": "SevenTicketPin" }),
    _createVNode(_unref(IdleModalNotification))
  ], 64 /* STABLE_FRAGMENT */))
}
}

})