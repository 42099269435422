import { v4 as uuidv4 } from 'uuid';
import { toRaw } from 'vue';
import i18n from '@/plugins/i18n';
import {
  ClientTicketActions,
  type Ticket,
  type TicketInstance,
  ProductTicketResponse,
  TicketResponse,
} from '@/modules/seven-betslip-api';
import { useTboModulesStore } from '@/modules/tbo';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { errorParser } from '@/common/services/error-parser';
import { logService } from '@/common/services/logger';
import ticketManager from '@/modules/tickets/ticketManager';
import { operatorService } from '@/modules/operator';
import { isTicketCheckAbortedError, useTicketCheckStore } from '@/modules/ticket-check';
import { prepareTicketForPrint, shouldPrintPayoutTicket } from '@/modules/tickets/helpers';
import TicketPayoutError from '@/modules/tickets/errors/TicketPayoutError';
import { printService } from '@/modules/print';
import { createTicketMetaData } from './helpers';
import * as tboTicketsValidationService from './tboTicketsValidationService';
import type ActionSuccessResponse from '../tickets/types/ActionSuccessResponse';

const { t } = i18n.global;
const LOG_PREFIX = '[tboPayoutService]';

const showPayoutFailedNotification = (error: Error) => {
  const notificationsStore = useNotificationsStore();
  notificationsStore.closeNotificationWithId('tbo_payout_error_notif'); // if there was previous error notif left

  if (!isTicketCheckAbortedError(error)) {
    notificationsStore.show({
      message: error.message,
      delay: 3000,
      type: TNotificationTypeEnum.warning,
      id: 'tbo_payout_error_notif',
    });
  }
};

const validateOperatorPayoutPermission = (ticket: Ticket) => new Promise<{
  ticket: Ticket
}>((resolve, reject) => {
  const tboModuleStore = useTboModulesStore();
  const hasPermission = tboModuleStore.hasPermission('Tickets', 'payoutTicket');

  if (!hasPermission) {
    reject({
      message: t('notifications.permissions_forbidden'),
      code: 'T_PAYOUT_PERMISSION_DENIED',
    });
  }

  resolve({ ticket });
});

const handlePayoutTicketPrint = (
  ticket: TicketInstance,
  ticketResponse: TicketResponse | ProductTicketResponse,
) => {
  const productId = ticket.getProductDisplayId();
  if (shouldPrintPayoutTicket(productId)) {
    const notificationsStore = useNotificationsStore();
    // we recieve ProductTicket as Proxy so we need to do toRaw here
    const ticketForPrint = prepareTicketForPrint(toRaw(ticket), ticketResponse);

    printService.printJob(
      {
        type: 'ticket',
        action: ClientTicketActions.Payout,
        productId,
      },
      ticketForPrint,
    ).catch((err: any) => {
      logService.error(
        `${LOG_PREFIX} Failed to print ticket payout from Operator action!`,
        {
          ticketData: ticketForPrint,
          code: 'T_TICKET_PAYOUT_PRINT_OPERATOR_ERROR',
          product_displayid: productId,
          request_id: ticket.getRequestUuid(),
          ticket_code: ticket.getDisplayId(),
          operator_id: operatorService.data.uuid,
          operator_name: operatorService.data.username,
          upstream_code: err.upstream_code,
          upstream_message: err.upstream_message,
        },
      );
      notificationsStore.show({
        message: err.message,
        type: TNotificationTypeEnum.warning,
        delay: 3000,
      });
    });
  }
};

/**
 * @throws {TicketPayoutError}
 */
const payoutTicket = (ticket: TicketInstance) => new Promise<ActionSuccessResponse>(
  (resolve, reject) => {
    const notificationsStore = useNotificationsStore();

    tboTicketsValidationService.validateTBOPayout(ticket)
      .then(({ ticket: validatedTicket }) => {
        const productId = validatedTicket.getProductDisplayId();
        const requestUuid = validatedTicket.getRequestUuid() || uuidv4();

        return printService.getPrinterStatus().then(() => {
          const metaData = createTicketMetaData(productId, requestUuid);
          const ticketCheckStore = useTicketCheckStore();

          notificationsStore.show({
            message: t('ticket.pending_action'),
            type: TNotificationTypeEnum.info,
            id: 'ticket.pending_action',
          });

          return ticketManager.payoutTicket(validatedTicket, metaData, true).then((response) => {
            ticketCheckStore.clearTicketPin(true);
            resolve(response);
          }).catch((error) => {
            (ticketCheckStore.result as TicketInstance).setPin('');

            showPayoutFailedNotification(error);
            reject(error);
          });
        }).catch((printerStatusError: any) => {
          const formattedError = new TicketPayoutError(
            printerStatusError.message,
            'TBO_TICKET_PAYOUT_PRINT_ERROR',
            {
              context: {
                notificationDetails: {
                  type: TNotificationTypeEnum.error,
                },
              },
              cause: printerStatusError,
            },
          );
          showPayoutFailedNotification(formattedError);
          reject(formattedError);
        });
      })
      .catch((payoutValidationError) => {
        logService.error(`${LOG_PREFIX} TboBeforeTicketPayout error detected`, {
          ticket,
          product_displayid: ticket.getProductDisplayId(),
          ticket_code: ticket.getDisplayId(),
          request_id: ticket.getRequestUuid(),
          ...errorParser.parseUpstream(payoutValidationError),
          code: 'T_TBO_TICKET_PAYOUT_VALIDATION_ERROR',
        });
        const formattedError = new TicketPayoutError(
          payoutValidationError.message,
          'T_TBO_TICKET_PAYOUT_VALIDATION_ERROR',
          {
            context: {
              notificationDetails: {
                type: TNotificationTypeEnum.warning,
              },
            },
            cause: payoutValidationError,
          },
        );
        showPayoutFailedNotification(payoutValidationError);
        reject(formattedError);
      }).finally(() => {
        notificationsStore.closeNotificationWithId('ticket.pending_action');
      });
  },
);

export {
  validateOperatorPayoutPermission,
  handlePayoutTicketPrint,
  payoutTicket,
};
