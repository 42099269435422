import { useTicketCheckStore } from '@/modules/ticket-check';
import { betslipApiHelpers } from '@/modules/seven-betslip-api';
import { operatorService } from '@/modules/operator';

/* @ngInject */
function SevenTicketService(
  $timeout,
  $http,
  $log,
  $q,
  $filter,
  nabMessaging,
  SevenTicketConfig,
  LOCAL_TICKET_STATUS,
  LOCAL_TICKET_ACTIONS,
  PENDING_TICKET_TRESHOLD,
  errorParser,
  GravitySettings
) {
  // Maps backend ticket status with local.
  // It is used to update local status based on backend status response.
  // Product can send their own mapping using SevenTicketConfigData definition.
  var TICKET_STATUSES_MAPPER = {
    open: LOCAL_TICKET_STATUS.ACCEPTED,
    accepted: LOCAL_TICKET_STATUS.ACCEPTED,
    in_play: LOCAL_TICKET_STATUS.ACCEPTED,
    rejected: LOCAL_TICKET_STATUS.REJECTED,
    closed: LOCAL_TICKET_STATUS.CLOSED,
    payedout: LOCAL_TICKET_STATUS.PAYEDOUT,
    paidout: LOCAL_TICKET_STATUS.PAYEDOUT,
    cancelled: LOCAL_TICKET_STATUS.CANCELLED,
    canceled: LOCAL_TICKET_STATUS.CANCELLED,
    used: LOCAL_TICKET_STATUS.USED,
    won: LOCAL_TICKET_STATUS.ACCEPTED,
    lost: LOCAL_TICKET_STATUS.ACCEPTED,
    afterclosed: LOCAL_TICKET_STATUS.ACCEPTED
  };

  let ticketCheckStore = {};

  /**
   * @typedef {Object} SevenTicket
   * @property {String} action
   * @property {String} check
   * @property {String} id
   * @property {String} lang
   * @property {String} partialUrl
   * @property {String} payin
   * @property {String} product
   * @property {String} reqUuidCheck - url for checking tickets
   * @property {String} requestUuid
   * @property {Number} sent - timestamp when tickt was sent to server
   * @property {Object} status
   * @property {String} status.value
   * @property {String} status.name
   * @property {String} translation - Game provider translated
   * @property {String} [ticketPin] - Security pin provided by products
   * @property {boolean} [taxVerified] - Ticket is verified by tax auth
   */

  /**
     *
     * @param {...SevenTicket} ticketData
     * @class SevenTicket
     */
  var SevenTicket = function (ticketData) {
    angular.extend(this, ticketData);

    /**
     * Timestamp when ticket was sent to server
     *
     * @type {Number}
     */
    this.pendingStartedAt = null;

    /**
     * Timeout for checking ticket on server after x sec
     *
     * @type {Promise}
     */
    this.statusCheckTimeout = null;

    /**
     * @type {SevenTicketConfigData}
     */
    this.config = SevenTicketConfig.getTicketConfig(this.product);

    /**
     * This is security pin entered/set locally from shop.
     * This is sent to server on payout/cancel ticket
     *
     * @type {string}
     */
    this.ticketPin = ticketData?.ticketPin || '';

    /**
     *
     * @type {Array}
     */
    this.availableActions = [];

    /**
     *
     * @type {null}
     */
    this.localStatus = null;

    /**
     * Flag if the ticket is verified by tax auth
     *
     * @type {boolean}
     */

    this.taxVerified = ticketData?.taxVerified || false;

    /**
     * Ticket tax data
     *
     * @type {Object}
     */
    this.tax = ticketData?.tax || {};

    /**
     *
     * @type {null}
     */

    this.printTemplate = null;

    /**
     * Loyalty card data
     *
     * @type {null}
     */
    this.loyaltyDetails = null;

    /**
     * List of ticket changes that happened locally
     *
     * @type {Array.<{id: string, description: string, timestamp: number}>}
     */
    this.localRevisions = [];
  };

  /**
  * Has ticket pin - static method for checking if code is bundled with ticket pin
  *
  * @param code
  * @returns {boolean}
  * @static
  */
  SevenTicket.hasTicketPin = function (code) {
    var lastIdx = code.lastIndexOf('.');
    if (lastIdx !== -1 && lastIdx >= 2) {
      return lastIdx;
    }
    return false;
  };

  /**
  * Strip ticket pin - static method for removing ticket pin from barcode
  *
  * @param code
  * @param lastIdx
  * @returns {boolean}
  * @static
  */
  SevenTicket.stripTicketPin = function (code, lastIdx) {
    return code.slice(0, lastIdx);
  };

  /**
  * Extract ticket pin - static method for extracting ticket pin from barcode
  *
  * @param code
  * @param lastIdx
  * @returns {string}
  * @static
  */
  SevenTicket.extractTicketPin = function (code, lastIdx) {
    return code.slice(lastIdx + 1);
  };

  /**
  * Get display id of ticket
  *
  * @memberof SevenTicket
  */
  SevenTicket.prototype.getDisplayId = function () {
    var foundHash;
    // this is hotfix
    // we need find SportsbookSM ticket id
    if (this.ticketHashes) {
      foundHash = $filter('filter')(this.ticketHashes, { type: 'Normal' }, true)[0];
      if (foundHash) {
        return foundHash.hash;
      }
    }
    return this.id;
  };

  /**
  * Get request uuid of ticket
  *
  * @memberof SevenTicket
  */
  SevenTicket.prototype.getRequestUuid = function () {
    return this.requestUuid;
  };

  /**
  * Set request uuid of ticket
  *
  * @memberof SevenTicket
  */
  SevenTicket.prototype.addRequestUuid = function (requestUuid) {
    this.requestUuid = requestUuid;
  };

  /**
  * Set local pin
  *
  * @memberof SevenTicket
  * @param {String} pin
  */
  SevenTicket.prototype.setPin = function (pin) {
    this.ticketPin = pin;
  };

  /**
  * Sets ticketPin property inside slip. It represents pin entered in Retail popup.
  *
  * @memberof SevenTicket
  * @returns {String} pin
  */
  SevenTicket.prototype.getPin = function () {
    return this.ticketPin;
  };

  /**
   * Set  ticket print template
   *
   * @memberof SevenTicket
   * @param {String} template
   */
  SevenTicket.prototype.setPrintTemplate = function (template) {
    this.printTemplate = template;
  };

  /**
   * Set  ticket loyalt details
   *
   * @memberof SevenTicket
   * @param {Object} loyalty
   */
  SevenTicket.prototype.appendLoyaltyDetails = function (loyalty) {
    this.loyaltyDetails = loyalty;
  };

  /**
   * Ticket is verified by tax authorities
   * @memberof SevenTicket
   */
  SevenTicket.prototype.taxAuthVerified = function () {
    this.taxVerified = true;
  };

  /**
   * Remove server pin. Needed in situation like print copy
   * where pin is not needed anymore
   * @memberof SevenTicket
   */
  SevenTicket.prototype.clearServerPin = function () {
    this.ticketPin = false;
  };

  /**
   * Check is ticket resolved on server when scm resolve status fails
   *
   * @memberof SevenTicket
   */
  SevenTicket.prototype.startTicketChecker = function () {
    ticketCheckStore = useTicketCheckStore();

    this.statusCheckTimeout = $timeout(
      this.refreshStatusCheckInterval.bind(this),
      this.config.pendingTicketCheckInterval
    );
  };

  /**
   * Set ticket as pending
   *
   * @memberof SevenTicket
   */
  SevenTicket.prototype.setAsPending = function () {
    this.localStatus = LOCAL_TICKET_STATUS.PENDING;
    this.pendingStartedAt = Date.now();
  };

  /**
   * Update ticket object with new data
   *
   * @memberof SevenTicket
   * @param data
   */
  SevenTicket.prototype.update = function (data) {
    angular.extend(this, data);
  };

  /**
   * Add available actions to ticket object
   *
   * @memberof SevenTicket
   */
  SevenTicket.prototype.addAvailableActions = function () {
    var availableActions;
    var skip = false;
    var action;
    if (!this.config
            || !this.config.availableTicketActions) {
      return;
    }

    availableActions = this.config.availableTicketActions[this.getStatus()];

    this.availableActions.length = 0;

    if (availableActions && availableActions.length) {
      // eslint-disable-next-line vars-on-top
      for (var i = 0; i < availableActions.length; i += 1) {
        action = availableActions[i];
        skip = false; // i know for loop naming but it is ugly

        if (angular.isObject(action)) {
          // check conditions
          // eslint-disable-next-line vars-on-top
          for (var j = 0; j < action.conditions.length; j += 1) {
            // eslint-disable-next-line vars-on-top
            var condition = action.conditions[j];
            if (angular.isDefined(this[condition])
                  && this[condition] === false) {
              skip = true;
              break;
            }
          }

          if (skip) {
            // eslint-disable-next-line no-continue
            continue;
          }

          this.availableActions.push(LOCAL_TICKET_ACTIONS[action.action.toUpperCase()]);
        } else {
          this.availableActions.push(LOCAL_TICKET_ACTIONS[action.toUpperCase()]);
        }
      }
    } else {
      // show info about no action
      $log.debug('[SevenTicketCenter] Ticket status doesn\'t have any action: '
        + this.getStatus(), this.config.availableTicketActions);
    }
  };

  /** Check superbonus
     * @return {boolean}
     */
  SevenTicket.prototype.isSuperBonus = function () {
    return this.superBonus?.status?.value.toUpperCase() === 'WON';
  };

  /**
     * Get ticket status
     *
     * @memberof SevenTicket
     * @return {string}
     */
  SevenTicket.prototype.getStatus = function () {
    var ticketStatus;
    if (!this.status) return '';

    ticketStatus = this.status.value
      ? this.status.value.toLowerCase()
      : this.status.toLowerCase();

    return ticketStatus;
  };

  /**
   * Is ticket won. This ticket can be payed out if call returns true
   *
   * @memberof SevenTicket
   * @return {boolean}
   */
  SevenTicket.prototype.isWon = function () {
    // This line takes care of NGS games with superbonus set to won.
    // Ticket can be in lost state but if it has superbonus we need to payout ticket.
    // Also ticket can be in play but superbonus is set to won.
    // In that case we must wait for ticket to be resolved (not in play).
    // In case the ticket is expired, then it cannot be won even with superbonus
    if (
      this.isSuperBonus()
      && this.getStatus() !== 'in_play'
      && this.getStatus() !== 'expired'
    ) {
      return true;
    }
    return ['won', 'won_approved', 'cashback'].indexOf(this.getStatus()) >= 0;
  };

  /**
   * Update ticket state after it was successfully approved on server
   *
   * @memberof SevenTicket
   * @param {SevenTicket} ticketResponse
   * @param {String} action
   */
  SevenTicket.prototype.updateOnActionResolve = function (ticketResponse, action) {
    // get new status sent from backend
    var ticketStatus = ticketResponse.status.value ? ticketResponse.status.value.toLowerCase()
      : ticketResponse.status.toLowerCase();
    // get status definiton
    var statusDefinition = this.getTicketStatusDefinition(ticketStatus);
    var localStatus = null;

    // set new local status
    if (statusDefinition) {
      // if product mapped its backend status with our local
      localStatus = LOCAL_TICKET_STATUS[statusDefinition.localStatus.value.toUpperCase()];
    } else {
      // set status according to our internal mapping
      localStatus = TICKET_STATUSES_MAPPER[ticketStatus];
    }

    this.addRevision({
      group: 'localTicketStatusChanged',
      description: 'Changed from ' + this.localStatus + ' to ' + localStatus,
      newStatus: localStatus
    });
    ticketResponse.localStatus = localStatus;
    ticketResponse.action = action;
    // update ticket in memory
    this.update(ticketResponse);
    this.stopCheckingStatus();
  };

  /**
   * @memberof SevenTicket
   */
  SevenTicket.prototype.refreshStatusCheckInterval = function () {
    var self = this;
    const printTicketOnPayout = operatorService.isLoggedIn() && !GravitySettings.getModuleDataKeyValue(
      'module.' + this.product + '.ticketCheck',
      'showTicketDetailsFrameInBackOffice'
    );
    if (Date.now() - this.pendingStartedAt > this.config.pendingTicketRejectPeriod) {
      // Ticket is not on server after few checks => stop checking,
      $log.error('[SevenTicketCenter] RefreshStatusCheckInterval - Ticket is not on server - stop checking', {
        request_id: this.requestUuid,
        action: this.action,
        ticket_code: this.id || '',
        product_displayid: this.product || '',
        ticket: this,
        code: 'T_TICKET_NOT_FOUND_ON_SERVER'
      });
      this.stopCheckingStatus();
      this.addRevision({
        group: 'localTicketStatusChanged',
        description: 'Changed from ' + this.localStatus + ' to ' + LOCAL_TICKET_STATUS.UNKNOWN,
        newStatus: LOCAL_TICKET_STATUS.UNKNOWN
      });
      this.localStatus = LOCAL_TICKET_STATUS.UNKNOWN;
      nabMessaging.publish('TicketManager:pendingTicketRejectPeriodPassed', {
        messageTcKey: 'ticket.pending_reject_period_passed',
        ticket: this
      });
      return;
    }

    this.checkTicketStatus().then((response) => {
      if (this.action === 'Payout' && printTicketOnPayout) {
        ticketCheckStore.setResult(betslipApiHelpers.createTicket(response));
      }
      this.stopCheckingStatus();
    }, function () {
      self.statusCheckTimeout = $timeout(
        self.refreshStatusCheckInterval.bind(self),
        self.config.pendingTicketCheckInterval
      );
    });
  };

  /**
   * @memberof SevenTicket
   */
  SevenTicket.prototype.stopCheckingStatus = function () {
    if (this.statusCheckTimeout) {
      $timeout.cancel(this.statusCheckTimeout);
      this.statusCheckTimeout = null;
    }
  };

  /**
   * @memberof SevenTicket
   */
  SevenTicket.prototype.checkTicketStatus = function (extendData) {
    var url = this.action === 'Add' ? this.reqUuidCheck + this.requestUuid + '/product/' + this.partialUrl
      : this.check + this.getDisplayId().toUpperCase() + '.json';

    $log.warn('[SevenTicketCenter] Checking ticket status with http check', {
      request_id: this.requestUuid,
      action: this.action,
      ticket_code: this.id || '',
      product_displayid: this.product || '',
      extendData,
      code: 'T_TICKET_CHECK_STATUS_WITH_HTTP'
    });

    return $http({
      url: url,
      method: 'GET',
      headers: {
        'SEVEN-LOCALE': this.lang
      },
      timeout: 30000
    }).then(
      function (response) {
        var ticketStatus = response.data.status.value ? response.data.status.value.toLowerCase()
          : response.data.status.toLowerCase();
        var data;

        // eslint-disable-next-line no-mixed-operators
        if (this.action === 'Add' && ticketStatus === 'pending'
                // eslint-disable-next-line no-mixed-operators
                || this.action === 'Cancel' && ['closed', 'cancelled', 'canceled'].indexOf(ticketStatus) < 0
                // eslint-disable-next-line no-mixed-operators
                || (this.action === 'Payout' && ['payedout', 'paidout'].indexOf(ticketStatus) < 0)) {
          $log.info('[SevenTicketCenter] Ticket found on server but not yet resolved (ticket check http).', {
            code: 'T_TICKET_CHECK_STATUS_WITH_HTTP_FOUND_NOT_RESOLVED',
            request_id: this.requestUuid,
            action: this.action,
            ticket_code: this.id || '',
            product_displayid: this.product || '',
            response: response.data,
            details: {
              ticketStatus
            }
          });
          return $q.reject();
        }

        data = {
          response: response.data,
          localTicket: this
        };

        $log.info('[SevenTicketCenter] Ticket resolved by ticket check http', {
          code: 'T_TICKET_CHECK_STATUS_WITH_HTTP_FOUND',
          request_id: this.requestUuid,
          action: this.action,
          ticket_code: this.id || '',
          product_displayid: this.product || '',
          response: response.data,
          details: {
            ticketStatus
          }
        });

        if (extendData && Object.keys(extendData).length) {
          data.response = angular.extend(data.response, extendData);
        }

        nabMessaging.publish(
          'ticket:resolvedAction',
          data
        );
        return $q.resolve(data.response);
      }.bind(this),
      function (response) {
        var message;

        // If request is timed out or there are some network issues, do not stop the checker
        if (response.status && (response.status <= 0 || response.status > 500 || response.status === 499)) {
          $log.warn('[SevenTicketCenter] Ticket yet not found by http check', {
            request_id: this.requestUuid,
            action: this.action,
            ticket_code: this.id || '',
            product_displayid: this.product || '',
            ...errorParser.parseUpstream(response),
            code: 'T_TICKET_NOT_FOUND_WITH_HTTP'
          });
          return $q.reject();
        }

        this.stopCheckingStatus();
        $log.error('[SevenTicketCenter] Ticket not found on server - by http check', {
          request_id: this.requestUuid,
          action: this.action,
          ticket_code: this.id || '',
          product_displayid: this.product || '',
          ...errorParser.parseUpstream(response),
          code: 'T_TICKET_NOT_FOUND_ON_SERVER'
        });
        if (response.data && response.data.message) {
          message = response.data.details && response.data.details.length ? response.data.details[0].message
            : response.data.message;

          nabMessaging.publish('ticket:failedAction', {
            message: message,
            product: this.product,
            action: this.action,
            requestUuid: this.requestUuid
          });
        }
        return $q.resolve();
      }.bind(this)
    ).finally(function () {
      // Simulate SCM balance message in order to fetch latest balance
      nabMessaging.publish('NCM:balanceUpdated');
    });
  };

  /**
   * Check if ticket checker expired
   * @memberof SevenTicket
   * @returns {boolean}
   */
  SevenTicket.prototype.isTicketCheckerFinished = function () {
    return Date.now() - this.pendingStartedAt > this.config.pendingTicketRejectPeriod;
  };

  /**
   * Check if ticket checker expired
   * @memberof SevenTicket
   * @returns {boolean}
   */
  SevenTicket.prototype.isPendingTicketObsolete = function () {
    return Date.now() - this.pendingStartedAt > PENDING_TICKET_TRESHOLD && this.localStatus === LOCAL_TICKET_STATUS.PENDING;
  };

  /**
   * Get ticket status definition based on status from backend
   * and mapped config from product client
   * @memberof SevenTicket
   * @param {String} status
   * @returns {{localStatus: Object.{<value: string>} | boolean}
   */
  SevenTicket.prototype.getTicketStatusDefinition = function (status) {
    return this.config.statusConfig && this.config.statusConfig[status.toLowerCase()];
  };

  /**
   * Add new chnage to stack
   * @memberof SevenTicket
   * @param {Object} data
   * @param {String} data.group - used to group chnages from same context
   * @param {String} data.description - friendly descrtiption of change
   */
  SevenTicket.prototype.addRevision = function (data) {
    // attach date of change
    data.timestamp = new Date().getTime();
    this.localRevisions.push(data);
  };

  /**
 * Get possible payout amount. This is a max amount that player can win in the future.
 *
 * displayPayout - Used by `Prematch Betting` and `Live Betting`
 * possiblePayout - Used by NGS
 * @memberof SevenTicket
 * @returns {number}
 */
  SevenTicket.prototype.getPossiblePayoutAmount = function () {
    return this.displayPayoutAmount
          || this.paidOutAmount
          || this.displayPayout
          || this.possiblePayout
          || this.payoutAmountMax;
  };

  /**
   * Get payout amount that will be paid to player after ticker is set as paidout on server.
   *
   *   Live MTS payout
   *   Prematch MTS payout
   *   SM payoutAmountMax
   *   NGS -> payout
   * @memberof SevenTicket
   * @returns {number}
   */
  SevenTicket.prototype.getPayoutAmount = function () {
    return this.paidOutAmount
          || this.payout || this.totalAmount;
  };

  /**
   * Get payout amount. It includes tax value. It is only ok to get this value
   * when ticket is in WON status.
   *
   * In case we have a super bonus on ticket this value will be 0 or undefined. In case of undefined we will
   * return 0.
   *
   *   Live MTS, Prematch MTS, NGS winnings
   *   SM paidOutWinningAmount
   * @memberof SevenTicket
   * @returns {number}
   */
  SevenTicket.prototype.getPayoutAmountWithTax = function () {
    return (this.cashout?.cashoutEnabled && this.cashout?.winnings)
      || this.winnings
      || this.paidOutWinningAmount
      || 0;
  };

  /**
   * Get payout tax amount
   *
   *   Seven possiblePayoutTax or payoutTax
   *   SM paidOutTaxAmount
   * @memberof SevenTicket
   * @returns {number}
   */
  SevenTicket.prototype.getTaxAmount = function () {
    return this.possiblePayoutTax
        || this.payoutTax
        || this.paidOutTaxAmount;
  };

  /**
  * Get Product Display Id
  *
  * @memberof SevenTicket
  * @returns {String}
  */
  SevenTicket.prototype.getProductDisplayId = function () {
    return this.product;
  };

  /**
  * Returns full amount to payout - including taxes and bonuses
  *
  * @memberof SevenTicket
  * @returns {number}
  */
  SevenTicket.prototype.getFullPayoutAmount = function () {
    let winnings = this.getPayoutAmountWithTax();
    if (this.isSuperBonus()) {
      winnings += this.superBonus.amount;
    }

    return winnings;
  };

  /**
  * Get stake:
  *  paymentAmount/payment.value - SM
  *  payin for other games
  *
  * @memberof SevenTicket
  * @returns {number}
  */
  SevenTicket.prototype.getPayment = function () {
    return this.payin
      || this.paymentAmount
      || this.payment?.value
      || this.stake;
  };

  /**
  * Append tax data
  *
  * @memberof SevenTicket
  * @returns {number}
  */
  SevenTicket.prototype.setTaxData = function (data) {
    this.tax = data;
  };

  /**
  * Get tax data
  *
  * @memberof SevenTicket
  * @returns {number}
  */
  SevenTicket.prototype.getTaxData = function () {
    return this.tax;
  };

  SevenTicket.prototype.getPayoutTax = function () {
    return this.payoutTax;
  };

  SevenTicket.prototype.getLoyaltyCard = function () {
    // do nothing, we don't need this logic in legacy part of app
  };

  return SevenTicket;
}

export default SevenTicketService;
