import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import i18n from '@/plugins/i18n';
import { SEVEN_VOUCHER_DISPLAY_ID, VoucherResponse, VoucherStatus } from '@/modules/vouchers';
import { copyObject } from '@/common/services/helpers';
import type {
  TicketResponse,
  ProductTicketResponse,
  ProductTicketSportsbookSMResponse,
  ClientTicketActions,
} from './types';
import ClientTicket from './models/ClientTicket';
import ProductTicket from './models/ProductTicket';
import ProductTicketSM from './models/ProductTicketSM';
import TicketEventErrorResponse from './interfaces/TicketEventErrorResponse';

const { t } = i18n.global;

export type TicketInstance = ClientTicket | ProductTicket | ProductTicketSM;

const isTicketResponse = (
  ticketRes: TicketResponse | ProductTicketResponse | VoucherResponse,
): ticketRes is TicketResponse => (ticketRes as TicketResponse).slip !== undefined;

const isProductTicketSMResponse = (
  ticketRes: TicketResponse | ProductTicketResponse
  | ProductTicketSportsbookSMResponse | VoucherResponse,
): ticketRes is ProductTicketSportsbookSMResponse => !!(
  (ticketRes as ProductTicketSportsbookSMResponse).product === 'SportsbookSM'
);

const isTicketInstance = (r: TicketInstance | VoucherResponse | null): r is TicketInstance => (
  (r as TicketInstance)?.ticket !== undefined
);

const isVoucher = (
  res: TicketResponse | ProductTicketResponse | VoucherResponse,
): res is VoucherResponse => (res as VoucherResponse).product === SEVEN_VOUCHER_DISPLAY_ID;

const isVoucherInstance = (
  res: TicketInstance | VoucherResponse,
): res is VoucherResponse => (res as VoucherResponse).product === SEVEN_VOUCHER_DISPLAY_ID;

const canVoucherBePaidout = (voucher: VoucherResponse) => voucher.status === VoucherStatus.OPEN;

const isV2inCms = (ticketRes: TicketResponse | ProductTicketResponse | any):
  ticketRes is TicketResponse => {
  const settingsStore = useGravitySettingsStore();
  const productDisplayId = ticketRes.product
    || ticketRes.slip?.productDisplayId || ticketRes.config?.group?.id;
  return !!settingsStore.getByKey(`module.${productDisplayId}`)?.v2Ticket;
};

const isV2inCmsByProductId = (productDisplayId: string) => {
  const settingsStore = useGravitySettingsStore();
  return !!settingsStore.getByKey(`module.${productDisplayId}`)?.v2Ticket;
};

const createProductTicket = (
  ticketRes: ProductTicketResponse | ProductTicketSportsbookSMResponse,
) => {
  if (ticketRes.product === 'SportsbookSM') {
    return new ProductTicketSM(ticketRes as ProductTicketSportsbookSMResponse);
  }

  // this is old instance
  return new ProductTicket(ticketRes as ProductTicketResponse);
};

const createTicket = (
  ticketRes: TicketResponse | ProductTicketResponse | ProductTicketSportsbookSMResponse,
) => {
  const copy = copyObject(ticketRes);

  if (isTicketResponse(copy) || isV2inCms(copy)) {
    return new ClientTicket(copy);
  }

  return createProductTicket(copy);
};

const hasTicketPin = (code: string) => code.lastIndexOf('.') >= 2;

const extractTicketPin = (code: string) => code.slice(code.lastIndexOf('.') + 1);

const stripTicketPin = (code: string) => code.slice(0, code.lastIndexOf('.'));

const createLogDataFromTicket = (ticket: TicketInstance) => ({
  status: ticket.getStatus(),
  fullPayment: ticket.getFullPayoutAmount(),
  fullPayout: ticket.getFullPayoutAmount(),
  possiblePayoutAmount: ticket.getPossiblePayoutAmount(),
  payoutAmountWithTax: ticket.getPayoutAmountWithTax(),
  tax: ticket.getTaxData(),
  loyaltyCard: ticket.getLoyaltyCard(),
  localStatus: ticket.localStatus,
  action: ticket.action,
});

const getBasicTicketLogData = (ticket: TicketInstance) => ({
  ticket_code: ticket.getDisplayId(),
  product_displayid: ticket.getProductDisplayId(),
  request_id: ticket.getRequestUuid(),
  action: ticket.action,
});

const normalizeClientAction = (
  action: ClientTicketActions,
): ClientTicketActions => action.charAt(0).toUpperCase() + action.slice(1) as ClientTicketActions;

const getSevenBetslipErrorMessage = (response: TicketEventErrorResponse | null): Array<string> => {
  const messages = [];
  if (response) {
    if (response.httpCode === 403 && response.code === 19002) {
      messages.push(t('notifications.payout_ticket_on_cash_register'));
    } else if (response.httpCode === 412 && response.code === 1123100) {
      messages.push(t('notifications.retail_location_closed'));
    } else if (response.details?.length) { // check for details first
      /*
       * sometimes backed doesn't send any message inside details,'
       * that's why fallback to ticket.message
       */
      if (response.details[0].message) {
        response.details.forEach((err: any) => {
          messages.push(err.message);
        });
      } else {
        messages.push(response.message || '');
      }
    } else if (response.message) {
      // when no details available, use main message
      messages.push(response.message);
    } else {
      // if we don't have any message from server, just use default one
      messages.push(t('ticket.action_rejected'));
    }
  } else {
    // Typical for connection loss
    messages.push(t('notifications.default_error_message'));
  }
  return messages;
};

const getInstance = (res: TicketResponse | ProductTicketResponse | VoucherResponse) => {
  if (isVoucher(res)) {
    return res;
  }
  return createTicket(res);
};

export {
  createTicket,
  isTicketInstance,
  isVoucherInstance,
  hasTicketPin,
  extractTicketPin,
  stripTicketPin,
  createLogDataFromTicket,
  isTicketResponse,
  normalizeClientAction,
  createProductTicket,
  getSevenBetslipErrorMessage,
  isV2inCmsByProductId,
  isVoucher,
  getInstance,
  isProductTicketSMResponse,
  canVoucherBePaidout,
  getBasicTicketLogData,
  isV2inCms,
};
