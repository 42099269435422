// Module
var code = `<div> <table class="table table--luckysix--results" ng-repeat="result in results"> <thead> <tr> <th class="col-sm-24 pl-1 py-0-5"> {{ 'main.event' | translate }} {{ ::result.eventIdToday }} &nbsp;|&nbsp; {{ result.createdAt | dateToISO | date: 'yyyy-MM-dd HH:mm' }} </th> </tr> </thead> <tbody> <tr class="row"> <td class="col-sm-18 py-2"> <div class="row"> <span class="col-sm-8 pl-1">{{'luckysix.preballs' | translate}}</span> <span class="col-sm-16 px-0">{{'main.bonus_balls' | translate}}</span> </div> <div class="row"> <span class="col-sm-8 pl-1"> <div class="results-ball mx-0-5 my-0-5" ng-class="{'V6-color-{{(preball-1) % 8}}' : true,
                                            'V6-ball-{{(preball-1) % 8}}' : true}" ng-repeat="preball in ::result.draw|limitTo:5"> {{preball}} </div> </span> <span class="col-sm-16 px-0"> <div class="results-ball mx-0-5 my-0-5" ng-class="{'V6-color-{{(bonus-1) % 8}}' : true,
                                            'V6-ball-{{(bonus-1) % 8}}' : true
                                                }" ng-repeat="bonus in ::result.bonusBalls"> {{bonus}} </div> </span> </div> <p class="pt-2 pl-2">{{'main.drawn_balls' | translate}}</p> <div class="row"> <span class="col-sm-21 col-md-20 col-lg-16 col-xl-14 pl-2 pr-0"> <div class="results-ball mx-0-5 my-0-5" ng-class="{'V6-color-{{(ball-1) % 8}}' : true,
                                            'V6-ball-{{(ball-1) % 8}}' : true}" ng-repeat="ball in ::result.draw"> {{ball}} </div> </span> </div> </td> <td class="col-sm-6 py-2 px-0"> <p class="mb-0" ng-repeat="bet in ::result.specialBets"> <span class="special_bets_color" ng-bind="::bet.name"></span> <span class="float-sm-right special_bets_color text-uppercase pr-3" ng-bind="::bet.value"></span> </p> <p class="mb-0 special_bets_color"> <span ng-bind="::result.colorBet.name"></span> <span class="float-sm-right special_bets_color text-uppercase pr-3"> <span ng-repeat="value in result.colorBet.colorKeys | orderBy: reverse:true"> {{ value }}{{ \$last ? '' : ', ' }} </span> <span ng-if="!result.colorBet.value.length">-</span> </span> </p> </td> </tr> </tbody> </table> </div>`;
// Exports
var _module_exports =code;;
var path = '/games/lucky_six/results/templates/lucky_six_resultsV6.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;