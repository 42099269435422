import PubSub from 'pubsub-js';
import i18n from '@/plugins/i18n';
import { getService } from '@/common/services/ngBridge';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { AcceptorHookParam, acceptorsService, AcceptorAction } from '@/modules/acceptors';
import VueHooksManager from '@/common/services/HooksManager';
import { logService } from '@/common/services/logger';
import { BETSLIP_BLOCK_EVENT_ID, betslipBlockEventPubSub } from '@/modules/betslip';
import {
  type WorkingTimeData, type WorkingTimeDate, WorkingTimeDays, type WorkingTimeResultCheck,
} from './types';

let timeOverTranslated: string = '';
let timeHasNotStartedTranslated: string = '';
const days: WorkingTimeDays[] = Object.values(WorkingTimeDays);
let data: WorkingTimeData = {
  days: {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  },
  type: '',
  uuid: '',
};
let $rootScope: any;

const setData = (newData: WorkingTimeData): void => {
  data = newData;
};

const getParsedTime = (rawFormat: Array<string>): Date => {
  const time: Date = new Date();
  time.setHours(Number(rawFormat[0]), Number(rawFormat[1]), Number(rawFormat[2]));

  return time;
};

const parseDate = (rawDate: WorkingTimeDate): WorkingTimeDate => ({
  start: getParsedTime(rawDate.start.split(':')).toString(),
  end: getParsedTime(rawDate.end.split(':')).toString(),
});

const isLocationOpen = (): WorkingTimeResultCheck => {
  const currentDate: Date = new Date();
  const todayDayName: WorkingTimeDays = days[currentDate.getDay()];
  const workingHours: WorkingTimeDate[] = data.days[todayDayName];
  let parsedDates: WorkingTimeDate = {
    start: '',
    end: '',
  };
  const shiftDates: Array<{ type: string, date: Date }> = [];

  for (let i = 0; i < workingHours.length; i += 1) {
    parsedDates = parseDate(workingHours[i]);

    if (currentDate.getTime() < new Date(parsedDates.end).getTime()
    && currentDate.getTime() > new Date(parsedDates.start).getTime()) {
      return {
        message: '',
        hasError: false,
      };
    }

    shiftDates.push(
      {
        type: 'end',
        date: new Date(parsedDates.end),
      },
      {
        type: 'start',
        date: new Date(parsedDates.start),
      },
    );
  }

  if (!shiftDates.length) {
    return {
      id: 'workingHasNotStarted',
      message: timeHasNotStartedTranslated,
      hasError: true,
    };
  }

  shiftDates.sort((a: { date: Date }, b: { date: Date }) => {
    const distanceA = Math.abs(currentDate.getTime() - a.date.getTime());
    const distanceB = Math.abs(currentDate.getTime() - b.date.getTime());
    return distanceA - distanceB;
  });

  return {
    hasError: true,
    message: shiftDates[0].type === 'start' ? timeHasNotStartedTranslated : timeOverTranslated,
    id: shiftDates[0].type === 'start' ? 'workingHasNotStarted' : 'workingTimeOver',
  };
};

const validateWorkingHours = (): WorkingTimeResultCheck => {
  if (!Object.keys(data).length || (data.days && !Object.keys(data.days).length)) {
    return {
      message: '',
      hasError: false,
    };
  }

  return isLocationOpen();
};

const validateWorkingHoursHook = (preventShowingNotification?: boolean): Promise<void> => {
  const workingHoursCheck: WorkingTimeResultCheck = validateWorkingHours();
  const notificationsStore = useNotificationsStore();

  if (workingHoursCheck.hasError) {
    notificationsStore.closeNotificationWithId('ticket.pending_action');
    /**
     * When TBO, don't show notification on payout cause we
     * may get duplicated notifications. Since it is shown
     * in: tboPayoutService->payoutTicket->catch block of
     * tboTicketsValidationService.validateTBOPayout
     */
    if (!preventShowingNotification) {
      notificationsStore.show({
        type: TNotificationTypeEnum.warning,
        message: workingHoursCheck.message,
        delay: 5000,
      });
    }

    return Promise.reject({
      message: workingHoursCheck.message,
      code: 'WORKING_TIME_ERROR',
    });
  }

  return Promise.resolve();
};

const handleAcceptors = () => {
  const workingHoursCheck: WorkingTimeResultCheck = validateWorkingHours();

  if (workingHoursCheck.hasError) {
    const notificationsStore = useNotificationsStore();
    notificationsStore.show({
      type: TNotificationTypeEnum.error,
      message: workingHoursCheck.message,
      id: 'working_time_not_valid_error',
      delay: null,
    });
    betslipBlockEventPubSub.publish(BETSLIP_BLOCK_EVENT_ID, {
      id: 'WorkingTime',
      message: workingHoursCheck.message,
      productId: '*',
    });
    acceptorsService.onInitializationFinished(AcceptorAction.STOP).then(() => {
      logService.info('[serviceModeHandler] Stopping acceptors...', {
        code: 'T_DCD_STOP_ACCEPTORS_REQUEST',
        upstream_code: 'ServiceMode',
        upstream_message: 'STOP is called on acceptors initialization finished',
      });
      acceptorsService.stopAcceptors().catch((err) => {
        logService.error('[workingTimeService] Acceptors could not be stopped.', {
          code: 'T_WORKING_TIME_ACC_STOP_ERR',
          details: err,
        });
      });
    }).catch(() => {
      logService.error('[workingTimeService] Acceptors could not be stopped because they are not initialized.', {
        code: 'T_WORKING_TIME_ACC_INIT_ERR',
      });
    });
  }
};

const startWorkingTimeValidationChecker = (): void => {
  let previousWorkingTimeState: boolean = validateWorkingHours().hasError;

  window.setInterval(() => {
    const notificationsStore = useNotificationsStore();
    const workingHoursCheck: WorkingTimeResultCheck = validateWorkingHours();
    const stateDidChange: boolean = previousWorkingTimeState !== workingHoursCheck.hasError;
    previousWorkingTimeState = workingHoursCheck.hasError;

    if (workingHoursCheck.hasError && stateDidChange) {
      const { t } = i18n.global;
      logService.info('[workingTimeService] Stopping acceptors...', {
        code: 'T_DCD_STOP_ACCEPTORS_REQUEST',
        upstream_code: 'WorkingTime',
      });
      acceptorsService.stopAcceptors().catch((err) => {
        logService.error('[workingTimeService] Acceptors could not be stopped.', {
          code: 'T_WORKING_TIME_OVER_ACC_ERR',
          details: err,
        });
      });
      betslipBlockEventPubSub.publish(BETSLIP_BLOCK_EVENT_ID, {
        id: 'WorkingTime',
        message: workingHoursCheck.message,
        productId: '*',
      });
      notificationsStore.show({
        id: 'acceptors-start-error-working-time',
        message: t('acceptors_start_error_working_time'),
        type: TNotificationTypeEnum.error,
        delay: null,
      });
      logService.info('[workingTimeService] Working time is over or i did not yet start.', {
        code: 'T_WORKING_TIME_OUT_OF_WORK',
        details: {
          workingHoursCheck,
        },
      });
    } else if (stateDidChange) {
      logService.info('[workingTimeService] Starting acceptors...', {
        code: 'T_DCD_START_ACCEPTORS_REQUEST',
        upstream_code: 'WorkingTime',
      });
      acceptorsService.startAcceptors().catch((err) => {
        logService.error('[workingTimeService] Acceptors could not be started.', {
          code: 'T_WORKING_TIME_STARTED_ACC_ERR',
          details: err,
        });
      });
      $rootScope.$emit('7T:Betslip.Unblock', { namespace: 'WorkingTime' });
      notificationsStore.closeNotificationWithId('acceptors-start-error-working-time');
      notificationsStore.closeNotificationWithId('working_time_not_valid_error');

      logService.info('[workingTimeService] Working time has started.', {
        code: 'T_WORKING_TIME_STARTED',
        details: {
          workingHoursCheck,
        },
      });
    }
  }, 5000);
};

const closeAcceptorsNotifications = () => {
  const notificationsStore = useNotificationsStore();
  notificationsStore.closeNotificationWithId('acceptors-start-error-working-time');
};

const beforeAcceptorsStartValidation = (params: AcceptorHookParam): void => {
  const workingTimeValidationResult = validateWorkingHours();
  const { t } = i18n.global;
  closeAcceptorsNotifications();

  if (workingTimeValidationResult.hasError) {
    params.errorResponses.push({
      id: 'acceptors-start-error-working-time',
      message: t('acceptors_start_error_working_time'),
      code: 'T_BEFORE_ACCEPTORS_START_WORKING_TIME_ERROR',
    });
  }
};

const registerListeners = () => {
  PubSub.subscribe('7T:User.AuthorizationChanged', closeAcceptorsNotifications);
};

const init = (workingTimeData: WorkingTimeData): void => {
  const { t } = i18n.global;
  $rootScope = getService('$rootScope');
  data = workingTimeData || {};
  timeOverTranslated = t('working.time_over');
  timeHasNotStartedTranslated = t('working.time_has_not_started');

  registerListeners();
  handleAcceptors();
  startWorkingTimeValidationChecker();

  VueHooksManager.getHook('BeforeTicketPayout').tapPromise({
    name: 'BeforeTicketPayout.WorkingTime',
    fn: validateWorkingHoursHook,
  });

  VueHooksManager.getHook('BeforeTicketCancel').tapPromise({
    name: 'BeforeTicketCancel.WorkingTime',
    fn: validateWorkingHoursHook,
  });

  VueHooksManager.getHook('TboBeforeTicketPayout').tapPromise({
    name: 'TboBeforeTicketPayout.WorkingTime',
    fn() {
      return validateWorkingHoursHook(true);
    },
  });

  VueHooksManager.getHook('BeforeAcceptorsStart').tap('BeforeAcceptorsStart.WorkingTime', beforeAcceptorsStartValidation);
};

const workingTimeService = {
  setData,
  init,
  validateWorkingHours,
  isLocationOpen,
};

export default workingTimeService;
