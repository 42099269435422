/* eslint-disable import/no-cycle */
import angular from 'angular';
import '@nsftx/seven-ng-socket/dist/nab-ng-socket';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import { betslipBlockEventPubSub, BETSLIP_BLOCK_EVENT_ID } from '@/modules/betslip';
import { integratorService } from '@/modules/integrator';
import nabValidation from '../modules/nab_validation';
import commonModule from '../common';
import limitModule from '../limits';

(function () {
  /**
     * @module "7Terminal.Betslip"
     */
  angular.module('7Terminal.Betslip', [
    'pascalprecht.translate',
    'SevenClientLib',
    'SevenClient.Config',
    'SevenClientCore',
    limitModule,
    commonModule,
    nabValidation
  ]).run(function (
    $rootScope,
    BetslipService
  ) {
    betslipBlockEventPubSub.subscribe(BETSLIP_BLOCK_EVENT_ID, (event, eventData) => {
      const newBlock = BetslipService.block(eventData);

      // emit only if it is new unique block entry (namesapce is unique)
      if (newBlock) {
        integratorService.sendEvent('Betslip.Blocked', {
          context: {
            productId: eventData.productId,
            widgetType: 'PRODUCT_HOME'
          },
          config: {
            enforceEvent: true
          },
          data: {
            blockers: [eventData]
          }
        });
      }
    });

    $rootScope.$on('7T:Betslip.Unblock', function (e, data) {
      if (BetslipService.isBlockedBy(data.namespace)) {
        $rootScope.$evalAsync(function () {
          var unblockedEntries = BetslipService.unblock(data.namespace);
          integratorService.sendEvent('Betslip.Unblocked', {
            context: {
              productId: unblockedEntries[0].productId,
              widgetType: 'PRODUCT_HOME'
            },
            config: {
              enforceEvent: true
            },
            data: {
              blockers: unblockedEntries
            }
          });
        });
      }
    });

    $rootScope.$on('$destroy', function () {
      // wipe everything on app destroy
      _7Ticket.ts().reinitialise();
    });

    /**
         * Event for creating betslip with tickets
         *
         * @event 7T:Betslip:Create
         * @type {Object}
         * @property {String} action
         * @property {Object} data
         * @property {Object} data.betslip - Set minimum ticket winning
         * @property {Array.<TicketConfig>} data.betslip.tickets - Set minimum ticket winning
         */
    $rootScope.$on(
      '7T:Betslip.Create',

      /**
             * Creates betslip
             *
             * @function
             * @param {Object} e
             * @param {Object} eventData - A create betslip event.
             * @listens "7T:Betslip:Create"
             */
      function (e, eventData) {
        var newBetslip = BetslipService.createBetslip(eventData.data.betslip);

        /**
                 * Emits event about new betslip created
                 * together with new betslip object
                 *
                 * @event "7T:Betslip.Created"
                 * @type {Object}
                 * @property {String} action
                 * @property {Object} data
                 * @property {Object} data.betslip - New betslip
                 * @property {Array} data.productId
                 */
        $rootScope.$emit('7T:Betslip.Created', {
          betslip: newBetslip,
          productId: eventData.data.betslip.id
        });
      }
    );

    /**
         * Activate ticket on current active betslip or passed betslip
         *
         * @event 7T:Betslip.ActivateTicket
         * @type {Object}
         * @property {String} action
         * @property {Object} data
         * @property {String} data.betslipId - betslip id
         * @property {String} data.ticketId - ticket id
         */

    $rootScope.$on(
      '7T:Betslip.ActivateTicket',
      /**
             * Activates betslip
             *
             * @function
             * @param {Object} e
             * @param {Object} eventData
             * @listens "7T:Betslip.ActivateTicket"
             */
      function (e, eventData) {
        $rootScope.$evalAsync(function () {
          BetslipService.activateTicket(
            eventData.data.betslipId,
            eventData.data.ticketId
          );
        });
      }
    );

    /**
         * Listen for update of clicked bet on betslip
         *
         * @event 7T:Betslip:UpdateSelectedBet
         * @type {Object}
         * @property {String} action
         * @property {Object} data
         * @property {String} data.betslipId - betslip id
         * @property {String} data.ticketId - ticket id
         */

    $rootScope.$on(
      '7T:Betslip.UpdateSelectedBet',
      /**
             *
             * @param e
             * @param {Object} eventData
             * @param {Object} eventData.data
             * @param {Array.<Bet>} eventData.data.bets
             * @listens 7T:Betslip.UpdateSelectedBet
             * @fires "7T:Betslip.SelectedBetUpdated"
             */
      function (e, eventData) {
        $rootScope.$evalAsync(function () {
          var updatedBets = BetslipService.editTicket(eventData.data.bets);

          /**
                     * Emits info when selected bet is updated
                     *
                     * @event "7T:Betslip.SelectedBetUpdated"
                     * @type {Object}
                     * @property {String} action
                     * @property {Object} data
                     * @property {Object} data.ticket - New ticket state
                     * @property {Array} data.updatedBets - list of updated bets
                     */
          $rootScope.$broadcast('7T:Betslip.SelectedBetUpdated', {
            updatedBets: updatedBets,
            ticket: BetslipService.ticket,
            productId: BetslipService.ticket.config.ticketGroup
          });
        });
      }
    );

    /**
         * Listen for ticket update
         *
         * @event 7T:Betslip:UpdateTicketConfig
         * @type {Object}
         * @property {String} action
         * @property {Object} data
         * @property {Object} data.config - updated config values
         * @property {String} data.betslipId
         * @property {String} data.ticketId
         */
    $rootScope.$on(
      '7T:Betslip.UpdateTicketConfig',
      /**
             *
             * @param e
             * @param {Object} eventData
             * @param {Array} eventData.data
             * @listens 7T:Betslip:UpdateTicketConfig
             * @fires "7T:Betslip.TicketConfigUpdated"
             */
      function (e, eventData) {
        $rootScope.$evalAsync(function () {
          var updatedTicket = BetslipService.updateTicketConfig(
            eventData.data.betslipId,
            eventData.data.ticketId,
            eventData.data.config
          );

          /**
                     * Emits ticket with updated config
                     *
                     * @event "7T:Betslip.TicketConfigUpdated"
                     * @type {Object}
                     * @property {String} action
                     * @property {Object} data
                     * @property {Object} data.ticket - New ticket state
                     */
          $rootScope.$broadcast('7T:Betslip.TicketConfigUpdated', {
            ticket: updatedTicket,
            productId: eventData.productId
          });
        });
      }
    );

    /**
         * Listen for bets update
         *
         * @event 7T:Betslip.UpdateBets
         * @type {Object}
         * @property {String} action
         * @property {Object} data
         * @property {Object} data.bets - list of bets to update
         * @property {String} data.betslipId - betslip id
         */
    $rootScope.$on(
      '7T:Betslip.UpdateBets',

      /**
             *
             * @param e
             * @param {Object} eventData
             * @param {Array} eventData.data.bets
             * @param {String} eventData.data.ticketId
             * @param {String} eventData.data.betslipId
             * @listens 7T:Betslip.UpdateBets
             * @fires "7T:Betslip.BetsUpdated"
             */
      function (e, eventData) {
        $rootScope.$evalAsync(function () {
          var updatedBets = BetslipService.updateBets(eventData.data.bets, eventData.data.betslipId);

          $rootScope.$broadcast('7T:Betslip.BetsUpdated', {
            ticket: _7Ticket.ts().getBetslip(eventData.data.betslipId),
            betslipCollection: _7Ticket.betsCollection().getBetCollection(eventData.data.betslipId),
            updatedBets: updatedBets,
            productId: eventData.productId
          });
        });
      }
    );

    $rootScope.$on(
      '7T:Tickets.AddSuccess',

      /**
             *
             * @param e
             * @param {Object} message
             * @param {String} message.productId
             * @param {SevenTicket} message.ticketData
             */
      function (e, message) {
        if (_7Ticket.ts().getBetslip(message.productId)) {
          $rootScope.$evalAsync(function () {
            BetslipService.resetBetslip({
              id: message.productId
            });

            // Setting active tab/ticket while we are on different
            // game will cause som wired behavior, see T14197
            if (BetslipService.betslip?.config?.id === message.productId) {
              BetslipService.activateTicket(
                message.productId,
                false
              );
            }

            BetslipService.toggleBetslip(
              message.productId,
              false
            );
          });
        }
      }
    );

    /**
         * Listen for ticket re-creation
         *
         * @event 7T:Betslip.ReCreate
         * @type {Object}
         * @property {String} action
         * @property {Object} data
         * @property {Array} data.bets
         * @property {Object} data.ticket
         */
    $rootScope.$on(
      '7T:Betslip.ReCreate',

      /**
             *
             * @param e
             * @param {Object} eventData
             * @param {Object} eventData.data
             * @param {Array} eventData.data.bets
             * @param {Object} eventData.data.ticket
             * @listens 7T:Betslip.ReCreate
             * @fires "7T:Betslip.ReCreated"
             */
      function (e, eventData) {
        $rootScope.$evalAsync(function () {
          BetslipService.reCreateBetslip(eventData.data.ticket, eventData.data.bets);

          /**
                     * Emits new re-created ticket
                     *
                     * @event "7T:Betslip.ReCreated"
                     * @type {Object}
                     * @property {String} action
                     * @property {Object} data
                     * @property {Object} data.ticket
                     * @property {Object} data.sourceTicket
                     */
          $rootScope.$emit('7T:Betslip.ReCreated', {
            ticket: BetslipService.ticket,
            sourceTicket: eventData.data.ticket,
            productId: BetslipService.ticket.config.ticketGroup
          });
        });
      }
    );
  });
})();
